import { useEffect, useState } from "react";
import { GiveASecret } from "./GiveASecret";
import { GetASecret } from "./GetASecret";
import { Route, BrowserRouter, Routes, Navigate, useNavigate } from "react-router-dom";
import Login from "./Login";
import { ProtectedPage } from "./ProtectedPage";

export const App = () => {
  const [showGiveASecret, setShowGiveASecret] = useState(true);
  const [receivedSecret, setReceivedSecret] = useState("");
  const [receivedInitials, setReceivedInitials] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fade, setFade] = useState(true);
  
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    setFade(true);
  }, [showGiveASecret]);

  const handlePageToggle = () => {
    setFade(false);
    setTimeout(() => {
      setShowGiveASecret(!showGiveASecret);
    }, 500);
  };

  return  (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={  
            <div className={`transition-opacity duration-500 ease-in-out ${fade ? 'opacity-100' : 'opacity-0'}`}>
            {showGiveASecret ? (
              <GiveASecret
                showGetASecret={handlePageToggle}
                setReceivedSecret={setReceivedSecret}
                setReceivedInitials={setReceivedInitials}
              />
            ) : (
              <GetASecret
                showGiveASecret={handlePageToggle}
                receivedSecret={receivedSecret}
                receivedInitials={receivedInitials}
              />
            )}
          </div>
        }
        />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/protected" element={isAuthenticated ? <ProtectedPage /> : <Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  )
};

export default App;
