import { useEffect, useState } from "react";
import { ISecret } from "./models/secret";

const getAllSecrets = async () => {
  try {
    const response = await fetch("/api/fetchAllSecrets", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting secrets:", error);
  }
}

export const ProtectedPage = () => {
  const [secrets, setSecrets] = useState<ISecret[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getAllSecrets();
        setSecrets(data);
      } catch (error) {
        console.error("Error getting secrets:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  
  return (
    <div className="flex flex-col items-center justify-start w-full h-full pt-20 font-serif bg-stone-100 text-stone-500">
      <h1 className="text-3xl">Secrets Dashboard</h1>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <p>Loading...</p>
        </div>
      ) : (
      <div className="flex flex-col px-4 py-10">
        {secrets.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((secret, index) => (
          <div key={index} className="p-2 m-2 border rounded">
            <p>{secret.secret}</p>
            <p>{secret.initials}</p>
            </div>  
        ))}
      </div> )}
    </div>
  );
};
