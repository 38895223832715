import { useState } from "react";

const MAX_SECRET_LENGTH = 180;

interface GiveASecretProps {
  showGetASecret: () => void;
  setReceivedSecret: (receivedSecret: string) => void;
  setReceivedInitials: (receivedInitials: string) => void;
}

export const GiveASecret = ({
  showGetASecret,
  setReceivedSecret,
  setReceivedInitials,
}: GiveASecretProps) => {
  const [secret, setSecret] = useState("");
  const [initials, setInitials] = useState("");
  const [hintText, setHintText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSetSecret = (secretText: string) => {
    if (secretText.length <= MAX_SECRET_LENGTH) {
      setSecret(secretText);
    }
  };

  const handleSetInitials = (initialText: string) => {
    if (initialText.length < 4) {
      setInitials(initialText);
    }
  };

  const handleSubmit = async () => {
    if (secret.length < 8) {
      setHintText("please enter a full secret :)");
      return;
    }
    try {
      setHintText("");
      setLoading(true);
      const response = await fetch("/api/secrets", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ secret: secret, initials: initials }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`response: ${JSON.stringify(data)}`);
      setReceivedSecret(data.secret);
      
      if (data.initials && data.initials.trim() !== "") {
        setReceivedInitials(data.initials);
      } else {
        setReceivedInitials("???");
      }
      
      showGetASecret();
      setLoading(false);
    } catch (error) {
      console.error("Error sending secret:", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex items-start justify-center w-screen h-screen px-12 font-serif transition-opacity duration-500 ease-in-out py-28 bg-stone-100 text-stone-500">
      <div className="flex flex-col items-center w-full sm:w-1/2">
        <div className="flex flex-col items-center w-full h-full pb-16">
          <h1 className="pb-4 text-2xl">give a secret</h1>
          <textarea
            className="w-full h-40 px-4 py-2 border-2 rounded-md resize-none border-stone-500 bg-stone-100"
            value={secret}
            onChange={(e) => handleSetSecret(e.target.value)}
            placeholder="give a secret to get a secret"
            required={true}
          />
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm text-stone-500">
              {`${MAX_SECRET_LENGTH - secret.length}/${MAX_SECRET_LENGTH}`}
            </div>
          </div>

          <div className="flex flex-row items-center justify-end w-full gap-2">
            <div className="text-s">{`initials:`}</div>
            <textarea
              className="w-1/4 h-8 px-2 overflow-hidden border-2 rounded-md resize-none text-s border-stone-500 bg-stone-100"
              value={initials}
              onChange={(e) => handleSetInitials(e.target.value)}
              placeholder="ERB"
              required={true}
            />
          </div>
        </div>
        <button
          className="relative flex items-center justify-center w-full h-12 py-2 border-2 rounded-full border-stone-500 bg-stone-100 text-stone-500 hover:bg-stone-500 hover:text-stone-100 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <svg
              className="absolute w-5 h-5 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 0112.26-6.276L12 12H4z"
              ></path>
            </svg>
          )}
          {loading ? (
            <span className="invisible">{"sending"}</span>
          ) : (
            <span>{"send"}</span>
          )}
        </button>
        <div className="pt-3 text-sm text-red-400">{hintText}</div>
      </div>
    </div>
  );
};
