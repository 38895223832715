interface GetASecretProps {
  showGiveASecret: () => void;
  receivedSecret: string;
  receivedInitials: string;
}

export const GetASecret = ({
  showGiveASecret,
  receivedSecret,
  receivedInitials,
}: GetASecretProps) => {
  return (
    <div className="flex items-start justify-center w-screen h-screen px-12 font-serif transition-opacity duration-500 ease-in-out py-28 bg-stone-500 text-stone-100">
      <div className="flex flex-col items-center w-full sm:w-1/2">
        <div className="flex flex-col pt-16 h-1/2">
          <div className="flex flex-col items-center w-full h-full pt-0 pb-16">
            <div className="p-4 text-l">{receivedSecret}</div>
            <div className="w-full px-4 text-right h-3/4">
              {receivedInitials && receivedInitials.trim() !== ""
                ? `- ${receivedInitials}`
                : ""}
            </div>
          </div>
          <button
            className="flex items-center justify-center h-12 px-4 py-2 border-2 rounded-full border-stone-100 bg-stone-500 text-stone-100 hover:bg-stone-100 hover:text-stone-500 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => showGiveASecret()}
          >
            Send another secret?
          </button>
        </div>
      </div>
    </div>
  );
};
