import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      console.log("Correct password");
      onLogin();
      navigate("/protected");
    } else {
      alert("Incorrect password");
    }
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen font-serif bg-stone-100 text-stone-500">
      <div className="flex flex-col items-center">
        <h1 className="mb-4 text-2xl">Enter Password</h1>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="p-2 mb-4 border rounded"
        />
        <button onClick={handleLogin} className="p-2 rounded bg-stone-500 text-stone-100">
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;